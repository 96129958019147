import React from 'react';
import RenderRepos from '../components/ReposPage/RenderRepos';

function Repos() {
    return(
      <div className="body-repos">
        <RenderRepos />
      </div>
    )
}

export default Repos;