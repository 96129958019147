import React from 'react';
import RepoBranches from '../components/RepoBranchesPage/RepoBranches.js';

function Repo() {
    return(
      <div className="body-repo-branches">
        <RepoBranches />
      </div>
    )
}

export default Repo;