import React from "react";
import NavBarComponent from '../components/NavBarComponent.js';

function NavBar() {
  return (
    <NavBarComponent />
  )
}

export default NavBar;
